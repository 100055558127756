import { axios, service, $ajax, $xhr } from './request'

let apis = {}

let URLS = [
	// { name: 'create',    url: '/api/pai-eas/txt2img', method: 'post', contentType: 'application/json' },
	// { name: 'create',    url: '/api/comfy-ui/push',   method: 'post', contentType: 'application/json' }
	{ name: 'page', 	 url: '/api/pai-eas/page', 	  method: 'get' },
	{ name: 'paiPost',   url: '/api/pai-eas/post', 	  method: 'get' },
	{ name: 'pairemove', url: '/api/pai-eas/remove',  method: 'post'},
	{ name: 'paiDetail', url: '/api/pai-eas/detail',  method: 'get' },
	{ name: 'paiCount',  url: '/api/pai-eas/count',   method: 'get' },
	{ name: 'paiConfig', url: '/api/common/config',   method: 'get' },
	{ name: 'styles',    url: '/api/comfy-ui/style',  method: 'get' },
	{ name: 'detail',    url: '/api/comfy-ui/detail', method: 'get' },
	
]


URLS.forEach(api => {
	let { name, url, method, contentType, responseType } = api;
	let headers = { };
	if(contentType) {
		headers['Content-Type'] = contentType
	}
	apis[name] = function (data) {
		let requestParams = { url, method, headers }
		if (responseType) requestParams.responseType = responseType
		if (contentType) {
			if (method === 'post') requestParams.data = data
			if (method === 'get') requestParams.params = data
			if (contentType === 'application/json') {
				requestParams.data = JSON.stringify(data)
			}
		} else {
			requestParams.data = data
			requestParams.params = data
		}
		
		return axios(requestParams)
	}
})

apis.comfy = function (params) {
	return new Promise((resolve, reject) => {
		let data = new FormData()
		for (var key in params) {
			if (Array.isArray(params[key])) {
				params[key].forEach((item, index) => {
					data.append(`${key}[${index}]`, item)
				})
			} else {
				data.append(key, params[key])
			}
		}
		axios({ url: '/api/comfy-ui/push', method: 'post', data })
		.then((res) => {
			let timer = setInterval(() => {
				apis.detail({ id: res.data.task_id, onlyView: 1})
				.then((res) => {
					if (res.data.status == 2) {
						clearInterval(timer)
						reject('失败')
					}
					if (res.data.status == 3) {
						clearInterval(timer)
						resolve(res.data)
					}
				})
				.catch((err) => {
					clearInterval(timer)
					reject(err)
				})
			}, 1000)
		})
		.catch((err) => {
			reject(err)
		})
	})
}

export default apis